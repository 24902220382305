import * as React from "react";
import { graphql } from "gatsby";
import ComponentTemplatePage from "../../../../components/pages/templates/ComponentTemplatePage";

const ComponentTemplate = ({ data }) => (
  <ComponentTemplatePage
    title="Card UI Components | UI Card Templates | Uizard"
    category="Component Templates"
    url="/templates/component-templates/card/"
    metaDescription="Discover Uizard card UI component templates. The component card templates are professionally-designed and a good fit for any user-friendly design."
    description="
    h2:Customizable UI cards for all your design needs
    <br/>
    Drag-and-drop card UI component templates to easily design an eye-catching website or app.
    <br/>
    Designing a website or an app is never easy, especially when you don't have much time to learn a design tool.
    But in just a few minutes, you can design your own website or app with our <a:https://uizard.io/templates/component-templates/>drag-and-drop UI components</a>.
    You can use these templates anytime to design an eye-catching website, a web app, a desktop software, or a mobile app with absolute ease.
    <br/>
    h3:Save time with pre-made UI card components
    <br/>
    Make a website or a web or mobile app using card component template that always reflect your theme.
    The component card templates in Uizard are professionally-designed and a good fit for any user-friendly design.
    <br/>
    h3:Bring your mockups to life by using the different card component templates in unlimited color schemes
    <br/>
    Essentially, the component card templates in Uizard always reflect your theme.
    Design beautiful <a:https://uizard.io/mockups/>UI mockups</a> with various card component templates at your disposal.
    "
    pages={[
      "Booking card",
      "Pricing tiers large",
      "card overview card",
      "Delete confirmation dialog",
      "Feature cards round image",
      "Product photo gallery",
      "Big data table",
      "Calendar with image",
      "Blog posts column",
      "Social media post card",
      "Chat bot support",
      "Customer support card",
      "Subscribe to newsletter pop up",
      "Card feed 1 image",
      "Card feed 2 images",
      "Card feed 3 images",
      "Card row",
      "Tiny content highlight card",
      "Content card with picture",
      "Simple content card",
      "Pricing tiers with images",
      "Calendar card",
      "Dialog card",
      "Dialog card with input fields",
      "Upload content",
    ]}
    img1={data.image1.childImageSharp}
    img1alt="Screenshot showcasing the component template for designing cards"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot showcasing the component template for designing cards"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot showcasing the component template for designing cards"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot showcasing the component template for designing cards"
    img5={data.image5.childImageSharp}
    img5alt="Screenshot showcasing the component template for designing cards"
  />
);

export default ComponentTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/component-templates/card/card_1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/component-templates/card/card_2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/component-templates/card/card_3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/component-templates/card/card_4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/component-templates/card/card_5.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
